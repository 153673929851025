/* eslint-disable no-unused-vars */
import config from '../config/config.json';
import {html, PolymerElement} from '@polymer/polymer/polymer-element.js';
import {} from '@polymer/polymer/lib/elements/array-selector.js';
import {} from '@polymer/polymer/lib/mixins/mutable-data';

/* Astro Components */
import {RuxClock} from '@astrouxds/rux-clock/rux-clock.js';
import {RuxGlobalStatusBar} from '@astrouxds/rux-global-status-bar/rux-global-status-bar.js';
import {RuxIcon} from '@astrouxds/rux-icon/rux-icon.js';
// import { RuxPopUpMenu } from "@astrouxds/rux-pop-up-menu/rux-pop-up-menu.js";
import {RuxStatus} from '@astrouxds/rux-status/rux-status.js';
// import { RuxTabs } from "@astrouxds/rux-tabs/rux-tabs.js";
// import { RuxButton } from "@astrouxds/rux-button/rux-button.js";
// import { RuxNotification } from "@astrouxds/rux-notification/rux-notification.js";
// import { RuxModal } from "@astrouxds/rux-modal/rux-modal.js";
// import { RuxPopUpMenu } from "@astrouxds/rux-pop-up-menu/rux-pop-up-menu.js";
// import { RuxPushButton } from "@astrouxds/rux-push-button/rux-push-button.js";
// import { RuxProgress } from "@astrouxds/rux-progress/rux-progress.js";
import {RuxSegmentedButton} from '@astrouxds/rux-segmented-button/rux-segmented-button.js';
import {RuxSlider} from '@astrouxds/rux-slider/rux-slider.js';
// import { RuxSpectrumAnalyzer } from "@astrouxds/rux-spectrum-analyzer/rux-spectrum-analyzer.js";
// import { RuxTimeline } from "@astrouxds/rux-timeline/rux-timeline.js";
// import { RuxToggle } from "@astrouxds/rux-toggle/rux-toggle.js";
// import { RuxTree } from "@astrouxds/rux-tree/rux-tree.js";

/* GRM Services Templates and Styles */
import {AppMenu} from './app-menu.js';
import {StatusIndicators} from './status/status-indicators';
import {SubsystemMeasurands} from './subsystem/subsystem-measurands';
import template from './astro-app.html';
import css from './astro-app.css';
import astroCSS from './astro-css.js';

import systemsData from './systems-data.js';
/*
    TT&C Investigate Components
    Add TT&C Investigate specific components here
*/

/* eslint-enable no-unused-vars */
/**
 * @polymer
 * @extends HTMLElement
 */
export class AstroApp extends PolymerElement {
  static get properties() {
    return {
      statusData: {
        type: Object,
        value: {
          ucaStatus: {worstStatus: 'standby', numMessages: 45},
          timestamp: 1546641719633,
        },
      },
      systemsList: {
        type: Array,
        value: [],
      },
      satellite: {
        type: Object,
        value: function() {
          return {
            aos: new Date(new Date().getTime() - 5326750), // made up values
            los: new Date(new Date().getTime() + 4251312),
          };
        },
      },
    };
  }

  static get observers() {
    // runs _systemsListUpdate() each time selectedSystem or selectedSubstyem changes
    return ['_systemsListUpdate(selectedSystem)', '_systemsListUpdate(selectedSubsystem)'];
  }

  // controls setting & notifying Polymer about deep property changes which otherwise it would ignore
  _systemsListUpdate(updatedItem) {
    if (!updatedItem) return;

    // if the changed item is a top-level system
    if (updatedItem.subsystems && updatedItem.subsystems.length) {
      // reset top-level system selected states
      this.systemsList.forEach((element, index) => {
        this.set(['systemsList', index, 'selected'], false);
      });
      this.set(['systemsList', this.clickedSystemIndex, 'selected'], true);
    }

    // set the subsystem selected states
    this.systemsList[this.clickedSystemIndex].subsystems.forEach((element, index) => {
      this.set(['systemsList', this.clickedSystemIndex, 'subsystems', index, 'selected'], false);
    });
    this.set(['systemsList', this.clickedSystemIndex, 'subsystems', this.clickedSubsystemIndex, 'selected'], true);
  }

  clickSystem(e) {
    // if user just clicked a top-level System item
    if (e.currentTarget.getAttribute('role') !== 'treeitem') {
      // select a top-level system
      this.clickedSystemIndex = this.$.systemsList.indexForElement(e.currentTarget);
      this.$.systemsSelector.selectIndex(this.clickedSystemIndex);

      // always select the first item in the subsystem list
      this.clickedSubsystemIndex = 0;
      this.$.subsystemsSelector.selectIndex(this.clickedSubsystemIndex);
    } else {
      // if user just clicked a subsystem, select the clicked item
      this.clickedSubsystemIndex = this.$.subsystemsList.indexForElement(e.currentTarget);
      this.$.subsystemsSelector.selectIndex(this.clickedSubsystemIndex);
    }
  }

  _startStatusIndicatorsWebsocket() {
    const ws = new WebSocket(config.dataServers.statusIndicators);
    ws.addEventListener('message', (event) => {
      const payload = JSON.parse(event.data);
      this.statusData = payload;
    });
  }

  is(a, b) {
    return a === b;
  }

  clickModelComponent(e) {
    const outermostParent = this.getParentsUntil(e.target, 'svg').pop();
    this.selectModelComponent(
        outermostParent.getAttribute('data-component'),
        outermostParent.getAttribute('data-component-label')
    );
  }
  selectModelComponent(id, label) {
    this.selectedModelComponentId = id;
    this.selectedModelComponentLabel = label;
  }

  displayPopUpMenu(e) {
    if (e.detail.menu) {
      this.appMenuTarget = e.detail.target;
      this.menuItems = e.detail.menu;
    } else {
      this.appMenuTarget = e.currentTarget;
      this.menuItems = config.menuItems;
    }
  }

  getParentsUntil(elem, parent, selector) {
    const parents = [];
    for (; elem && elem !== document; elem = elem.parentNode) {
      if (parent) {
        if (elem.matches(parent)) break;
      }
      if (selector) {
        if (elem.matches(selector)) {
          parents.push(elem);
        }
        break;
      }
      parents.push(elem);
    }
    return parents;
  }

  constructor() {
    super();
    this.menuItems = config.menuItems;
    this.user = config.user;
  }

  connectedCallback() {
    super.connectedCallback();
    this._startStatusIndicatorsWebsocket();
    this.systemsList = systemsData.systemsList;
    this.measurands = systemsData.subsystemsMeasurands;

    // abstract this into model component eventually
    this.modelDiagram = {
      lens: {
        _id: 'lens',
        label: 'Lens',
        status: 'normal',
      },
      baffle: {
        _id: 'baffle',
        label: 'Baffle',
        status: 'normal',
      },
      detectionModule: {
        _id: 'detection-module',
        label: 'Detection Module',
        status: 'normal',
      },
      detector: {
        _id: 'detector',
        label: 'Detector',
        status: 'normal',
      },
      cooler: {
        _id: 'thermo-electric-cooler',
        label: 'Thermo-Electric Cooler',
        status: 'marginal',
      },
      electronics: {
        _id: 'electronics',
        label: 'Electronics',
        status: 'critical',
      },
    };

    // preselect first items in each list, necessary because this app as no empty state
    this.clickedSubsystemIndex = 0;
    this.clickedSystemIndex = 0;

    this.$.systemsSelector.selectIndex(this.clickedSystemIndex);
    this.$.subsystemsSelector.selectIndex(this.clickedSubsystemIndex);

    // preselect specific subsystem model component
    this.selectModelComponent(this.modelDiagram.baffle._id, this.modelDiagram.baffle.label);

    this.addEventListener('showPopUpMenu', this.displayPopUpMenu);
  }

  disconnectedCallback() {
    super.disconnectedCallback();

    this.removeEventListener('showPopUpMenu', this.displayPopUpMenu);
  }
  ready() {
    super.ready();
  }
  static get template() {
    return html([
      `
      <style include="astro-css">
        ${css}
      </style> 
      ${template}`,
    ]);
  }
}
customElements.define('astro-app', AstroApp);
