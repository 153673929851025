import {html, PolymerElement} from '@polymer/polymer/polymer-element.js';
import '@polymer/polymer/lib/elements/dom-if.js';

/* eslint-disable no-unused-vars */
import {RuxStatus} from '@astrouxds/rux-status/rux-status.js';
import {AstroApp} from '../astro-app';
/* eslint-enable no-unused-vars */

import template from './subsystem-measurands.html';
import css from './subsystem-measurands.css';

/**
 * @polymer
 * @extends HTMLElement
 */
export class SubsystemMeasurands extends PolymerElement {
  static get properties() {
    return {
      label: {
        type: String,
        value: function() {
          return '';
        },
      },
      measurands: {
        type: Array,
        value: function() {
          return [];
        },
      },
      filters: {
        type: Array,
        value: function() {
          return [
            {label: 'All', value: 'all'},
            {label: 'Marginal', value: 'caution'},
            {label: 'Critical', value: 'critical'},
          ];
        },
      },
      stringFilter: {
        type: String,
        value: '',
      },
      numWatched: {
        type: Number,
        computed: 'countWatched(measurands.*)',
      },
    };
  }

  is(a, b) {
    return a === b;
  }

  applyFilters(statusFilter, stringFilter) {
    return (measurand) => {
      // show this item if no filters are set
      if (statusFilter === 'all' && stringFilter === '') return true;
      // hide this item if statusFilter is set and doesn't match item's status
      if (statusFilter !== 'all' && statusFilter !== measurand.status) return false;
      // hide this item if stringFilter is set and item's measurement or mnemonic props don't contain it
      if (stringFilter !== '') {
        return (
          measurand.measurement.toLowerCase().includes(stringFilter.toLowerCase()) ||
          measurand.mnemonic.toLowerCase().includes(stringFilter.toLowerCase())
        );
      }
      // otherwise this item passes filters
      return true;
    };
  }

  clearFilters() {
    // clear the string filter
    this.stringFilter = '';

    // update the array of filters to change selected state to 'all'
    this.set('filters.selected', this.filters[0]);

    // set selected property of all element to true
    this.notifyPath('filters.0.selected', true);
  }

  sortMeasurandsByStatus(a, b) {
    function mapStatus(status) {
      switch (status) {
        case 'critical':
          return 0;
        case 'caution':
          return 1;
        default:
          // normal
          return 2;
      }
    }
    return mapStatus(a.status) > mapStatus(b.status) ? 1 : -1;
  }

  sortMeasurandByPropAsc(prop) {
    return function(a, b) {
      return a[prop] < b[prop] ? 1 : -1;
    };
  }

  sortMeasurandByPropDes(prop) {
    return function(a, b) {
      return a[prop] > b[prop] ? 1 : -1;
    };
  }

  handleChangeSort(e) {
    this.changeSort(e.target.getAttribute('data-sort-by'));
  }

  changeSort(newSort) {
    this.sortBy = newSort;
    if (this.sortBy === 'mnemonic' || this.sortBy === 'measurement') {
      this.sortFn = this.sortMeasurandByPropDes(this.sortBy);
    } else if (this.sortBy === 'value' || this.sortBy === 'watched') {
      this.sortFn = this.sortMeasurandByPropAsc(this.sortBy);
    } else {
      this.sortFn = this.sortMeasurandsByStatus;
    }
  }

  countWatched() {
    const watchedList = this.measurands.filter((item) => {
      return item.watched === true;
    });
    return watchedList.length;
  }

  toggleWatched(e) {
    this.measurands.forEach((measurand, index) => {
      let thisMeasurand = e.model.measurand;
      let isWatched = thisMeasurand.watched;
      if (measurand.mnemonic === thisMeasurand.mnemonic) {
        this.set(['measurands', index, 'watched'], !isWatched);
        this.notifyPath(['measurands', index, 'watched']);
      }
      thisMeasurand = null;
      isWatched = null;
    });
  }

  constructor() {
    super();
    this.changeSort('status');
  }

  connectedCallback() {
    super.connectedCallback();
  }

  disconnectedCallback() {
    super.disconnectedCallback();
  }

  ready() {
    super.ready();
  }

  static get template() {
    return html([
      ` <style include="astro-css">
          ${css}
        </style> 
        ${template}`,
    ]);
  }
}

customElements.define('subsystem-measurands', SubsystemMeasurands);
